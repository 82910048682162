import { Form } from "antd";
import { useCallback, useContext, useState } from "react";
import { useSetRecoilState } from "recoil";

import {
  currentDepotRiskyieldState,
  currentDepotState,
  currentDepotUserUriState,
  useRefreshcurrentDepotRiskYield
} from "@states/current.depot.state";
import { depotDetailsState } from "@states/depot.state";
import { financialPositionState } from "@states/financial-position.state";
import {
  Actions,
  strategyRiskReducer,
  strategyRiskState
} from "@states/strategy-risk.state";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

import { useApiErrorHandler } from "@hooks/error-handling/useApiErrorHandler";

import { ThemeContext } from "@pages/_app";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import useOnboardingLabels from "@src/hooks/onboarding/labels/useOnboardingLabels";
import useThemedModal from "@theme/hook/useThemedModal";
import { openIndependentInfoModal } from "@theme/modal/openThemedModal";
import type DepotDetailsDto from "smavesto.core/lib/types/dto/depot/DepotDetailsDto";
import type FinancialPositionDto from "smavesto.core/lib/types/dto/onboarding/FinancialPositionDto";
import { useRefreshState } from "@src/state/request.state";

export const useRiskModalForm = (destroyModal: () => void) => {
  const [form] = Form.useForm();

  const { theme } = useContext(ThemeContext);

  const { contextHolder } = useThemedModal();

  const [, userUri] = useAsyncSelector(currentDepotUserUriState);

  const labels = useOnboardingLabels("dashboard");

  const setStrategyRisk = useSetRecoilState(strategyRiskState);

  const [, currentDepot] = useAsyncSelector(currentDepotState);

  const refreshDepotDetails = useRefreshState(
    "depotDetails",
    "currentDepotOwner",
    currentDepot?.id
  );

  const [isSubmit, setIsSubmit] = useState(false);

  const [handleApiError] = useApiErrorHandler();

  const setRiskYieldFields = useCallback(
    (res?: DepotDetailsDto) => {
      if (!res) return;

      form.setFieldsValue({ investmentRisk: res.anlageRisiko });
      setStrategyRisk(
        strategyRiskReducer(Actions.initializeRisk(res.anlageRisiko))
      );
    },
    [form, setStrategyRisk]
  );

  const [, riskYield] = useAsyncSelector(currentDepotRiskyieldState);

  useAsyncSelector(depotDetailsState, setRiskYieldFields);

  const refreshRiskYield = useRefreshcurrentDepotRiskYield("loggedInUser");

  const setStrategy = useCallback(
    (res?: FinancialPositionDto) => {
      if (!res) return;
      setStrategyRisk(
        strategyRiskReducer(Actions.initializeStrategy(res.strategy))
      );
    },
    [setStrategyRisk]
  );

  useAsyncSelector(financialPositionState("loggedInUser"), setStrategy);

  const onFinish = async (values: { investmentRisk?: number }) => {
    if (!riskYield || !userUri) return;

    const body = { ...riskYield, anlagerisiko: values.investmentRisk ?? 15 };

    setIsSubmit(true);

    const putRiskYieldResponse = await smavestoCore.services.user.setRiskYield(
      userUri,
      body
    );

    if (isApiErrorResponse(putRiskYieldResponse)) {
      handleApiError(putRiskYieldResponse, "modal", "error");
      setIsSubmit(false);
    }

    refreshRiskYield();
    destroyModal();
    refreshDepotDetails();

    openIndependentInfoModal(
      labels.investmentSettingsModal.investmentRiskInfo,
      theme
    );

    setIsSubmit(false);
  };

  return { form, isSubmit, onFinish, contextHolder };
};
