import InvestmentRiskFormSection from "@page-components/onboarding/Investment/investment-risk/InvestmentRiskFormSection";
import ReallocationTooltip from "@src/components/layout/tooltips/ReallocationTooltip";
import useIsSmavestoPremium from "@src/hooks/supplier/useIsSmavestoPremium";
import { parseRiskNumValueToLabel } from "@src/page-components/onboarding/FinancialPosition/strategy/helpers";
import useThemedModal from "@src/theme/hook/useThemedModal";
import smavestoPremiumRiskFees from "@src/utils/cost-calculation/static/smavestoPremiumRiskFees";
import formatNumber from "@src/utils/format/formatNumber";

import { ModalFormContainer } from "../../Profile/ModalFormContainer";
import { useRiskModalForm } from "./useRiskModalForm";

interface RiskModalFormProps {
  destroyModal: () => void;
  isUnderage?: boolean;
  source?: "dashboard" | "onboarding";
}

export const RiskModalForm: React.FunctionComponent<RiskModalFormProps> = ({
  destroyModal,
  isUnderage,
  source
}) => {
  const isSmavestoPremium = useIsSmavestoPremium();
  const themedModal = useThemedModal();

  const { form, onFinish, isSubmit, contextHolder } =
    useRiskModalForm(destroyModal);

  const onFinishWrapper = async (values: any) => {
    const risk = values.investmentRisk as number;
    const riskLabel = parseRiskNumValueToLabel(risk);
    const riskFee = smavestoPremiumRiskFees[riskLabel];

    if (isSmavestoPremium) {
      const inst = themedModal.openModal("confirm", {
        title: "Vermögensverwaltungsgebühr",
        width: 600,
        content: isUnderage ? (
          <p>
            Das neue Anlagerisiko Ihres Kindes wird mit der nächsten
            Reallokation <ReallocationTooltip /> berücksichtigt. Die
            Vermögensverwaltungsgebühr für das Depot Ihres Kindes beträgt ab dem
            1. Tag des Folgemonats {formatNumber(riskFee.feePercentPerYear, 2)}%
            p.a..
          </p>
        ) : (
          <p>
            Ihr neues Anlagerisiko wird mit der nächsten Reallokation
            <ReallocationTooltip /> berücksichtigt. Ihre
            Vermögensverwaltungsgebühr beträgt ab dem 1. Tag des Folgemonats{" "}
            {formatNumber(riskFee.feePercentPerYear, 2)}% p.a..
          </p>
        ),
        okText: "Verstanden",
        cancelText: "Abbrechen",
        onOk: () => onFinish(values),
        onCancel: () => inst.destroy()
      });
    } else {
      onFinish(values);
    }
  };

  return (
    <ModalFormContainer
      title="Anlagerisiko"
      submitBtnLabel="Anlagerisiko speichern"
      isSubmit={isSubmit}
      form={form}
      onFinish={onFinishWrapper}
      onCancel={destroyModal}
    >
      <InvestmentRiskFormSection
        source={source}
        isUnderage={isUnderage}
        hideTitle
        form={form}
      />
      {themedModal.contextHolder}
      {contextHolder}
    </ModalFormContainer>
  );
};
